import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Paper,
  TextField,
  Snackbar,
  Grid,
  Container,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  padding24: {
    padding: 24,
  },
  marginTop24: {
    marginTop: 24,
  },
  marginTop8: {
    marginTop: 8,
  },
  link: {
    textDecoration: 'none',
  },
}))
const NewAccount = () => {
  const classes = useStyles()
  const [snack, setSnack] = useState(false)
  const [msg, setMsg] = useState('')

  function closeSnack() {
    setSnack(false)
  }

  function handleNew(event) {
    event.preventDefault()
    setMsg('New account has been created')
    setSnack(true)
  }
  return (
    <Layout>
      <SEO title="Create New Account" />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={msg}
      />
      <Container>
        <form
          noValidate
          onSubmit={handleNew}
          style={{ marginTop: 170, marginBottom: 84 }}
        >
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item md={4}>
              <Paper square>
                <div style={{ backgroundColor: '#F00', padding: 4 }}></div>
                <div className={classes.padding24}>
                  <Typography variant="h5" align="center">
                    New Account
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.marginTop24}
                    align="center"
                  >
                    Please fill the following form to create new account on IRS
                    Association
                  </Typography>
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Batch"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.marginTop24}
                  >
                    Create
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  )
}

export default NewAccount
